import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DeploymentForRegion } from "../../types/types";

type DeploymentForRegionState = {
  deploymentToUse: DeploymentForRegion;
};

console.log(
  "REACT_APP_SERVER_API_URL",
  `${process.env.REACT_APP_SERVER_API_URL}`,
);

const initialState: DeploymentForRegionState = {
  deploymentToUse: {
    countryCode: "GB",
    paymentsDeploymentUrl: `${process.env.REACT_APP_SERVER_API_URL}`,
    webAppDeploymentUrl: "",
  },
};

const deploymentForRegionSlice = createSlice({
  name: "providers",
  initialState,
  reducers: {
    setDeploymentToUse(
      state: DeploymentForRegionState,
      action: PayloadAction<DeploymentForRegion>,
    ) {
      console.log("action.payload", action.payload);
      state.deploymentToUse = action.payload;
    },
  },
});

export default deploymentForRegionSlice;
