import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { combineReducers, UnknownAction } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import deploymentForRegionSlice from "./slices/deployment-for-region";
import employerSlice from "./slices/employer";
import { healthkeyAPI } from "./slices/healthkeyAPI";
import providersSlice from "./slices/providers";
import servicesSlice from "./slices/services";
import usersSlice from "./slices/users";

const appReducer = combineReducers({
  providers: providersSlice.reducer,
  users: usersSlice.reducer,
  employer: employerSlice.reducer,
  services: servicesSlice.reducer,
  deploymentForRegion: deploymentForRegionSlice.reducer,
  [healthkeyAPI.reducerPath]: healthkeyAPI.reducer,
});

const rootReducer = (state: any, action: UnknownAction) => {
  if (action.type === "auth/logout") {
    storage.removeItem("persist:root");
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(
  {
    key: "root",
    version: 1,
    storage: storage,
    blacklist: [healthkeyAPI.reducerPath, "deploymentForRegion"],
  },
  rootReducer,
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(healthkeyAPI.middleware),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
