import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { useAppSelector } from "../store/hooks";
import { useHeaderQuery } from "../store/slices/api/aggregation-hooks";
import { User } from "../types/types";
const stripEmail = (str: string) => {
  return str.replace(
    /\/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/,
    "/:email",
  );
};

const stripResetToken = (str: string) => {
  return str
    .replace(
      /\/forgottenPasswordChangePassword\/\w+/,
      "/forgottenPasswordChangePassword/:resetToken",
    )
    .replace(
      /\/acceptInvite\/(\w+)\/\w+\/(\w+)/,
      "/acceptInvite/$1/:resetToken/$2",
    );
};

export const filterPrivateData = (str: string) => {
  return stripResetToken(stripEmail(str));
};

const usePageTitle = (callback: (titleText: string) => void) => {
  useEffect(() => {
    const observer = new MutationObserver(function (mutations) {
      const titleText = (mutations[0].target as HTMLElement).innerText;
      if (titleText) {
        callback(titleText);
      }
    });
    observer.observe(document.querySelector("title") as any, {
      subtree: true,
      characterData: true,
      childList: true,
    });
    return () => observer.disconnect();
  }, [callback]);
};

const calculateCustomDimensions = () => {
  return [
    {
      id: 1,
      value: undefined,
    },
    {
      id: 2,
      value: "false",
    },
    {
      id: 3,
      value: undefined,
    },
    {
      id: 4,
      value: "false",
    },
    {
      id: 5,
      value: "false",
    },
  ];
};

export const MatomoHistoryListener = ({
  children,
}: {
  children: ReactNode;
}) => {
  const location = useLocation();
  const { trackPageView, pushInstruction } = useMatomo();

  const updatedPageTitle = (titleText: string) => {
    const event = {
      documentTitle: titleText,
      href: `${process.env.REACT_APP_HOSTNAME}${filterPrivateData(location.pathname)}`,
    };

    trackPageView({
      ...event,
      customDimensions: calculateCustomDimensions(),
    });
  };

  usePageTitle(updatedPageTitle);

  useEffect(() => {
    pushInstruction("setUserId", undefined);
  }, [pushInstruction]);

  return <>{children}</>;
};
