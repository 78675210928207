import { DeploymentForRegion } from "../../../types/types";
import { healthkeyAPI } from "../healthkeyAPI";

const deploymentForRegionAPI = healthkeyAPI.injectEndpoints({
  endpoints: (builder) => ({
    deploymentForRegion: builder.query<DeploymentForRegion, void>({
      query: () => `/deploymentForRegion`,
    }),
  }),
});

export const { useDeploymentForRegionQuery } = deploymentForRegionAPI;
