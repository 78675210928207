import { ReactNode, useEffect } from "react";

import { useAppDispatch } from "../store/hooks";
import { useDeploymentForRegionQuery } from "../store/slices/api/geolocaised-api";
import deploymentForRegionSlice from "../store/slices/deployment-for-region";
import { PageLoader } from "./design-system/loader/PageLoader";

export const Geolocalised = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();

  const { data: deploymentForRegion, isLoading: deploymentForRegionIsLoading } =
    useDeploymentForRegionQuery();

  useEffect(() => {
    console.log("deploymentForRegion", deploymentForRegion);
    if (deploymentForRegion) {
      dispatch(
        deploymentForRegionSlice.actions.setDeploymentToUse(
          deploymentForRegion,
        ),
      );
    }
  }, [deploymentForRegion, dispatch]);

  if (deploymentForRegionIsLoading) {
    return <PageLoader />;
  }

  return <>{children}</>;
};
